import React from 'react';

import CODIGO2 from './images/gif/CODIGO2.gif';
import CODIGO3 from './images/gif/CODIGO3.gif';
import CODIGO4 from './images/gif/CODIGO4.gif';
import CODIGO5 from './images/gif/CODIGO5.gif';
import CODIGO6 from './images/gif/CODIGO6.gif';
import CODIGO7 from './images/gif/CODIGO7.gif';
import CODIGO8 from './images/gif/CODIGO8.gif';
import CODIGO9 from './images/gif/CODIGO9.gif';
import CODIGO10 from './images/gif/CODIGO10.gif';
import CODIGO11 from './images/gif/CODIGO11.gif';
import CODIGO12_1 from './images/gif/CODIGO12-1.gif';
import CODIGO13 from './images/gif/CODIGO13.gif';
import CODIGO14 from './images/gif/CODIGO14.gif';
import CODIGO15 from './images/gif/CODIGO15.gif';
import CODIGO16 from './images/gif/CODIGO16.gif';
import CODIGO17 from './images/gif/CODIGO17.gif';
import CODIGO18 from './images/gif/CODIGO18.gif';
import CODIGO19 from './images/gif/CODIGO19.gif';
import CODIGO20 from './images/gif/CODIGO20.gif';
import CODIGO21 from './images/gif/CODIGO21.gif';
import CODIGO22 from './images/gif/CODIGO22.gif';
import CODIGO23 from './images/gif/CODIGO23.gif';
import CODIGO24 from './images/gif/CODIGO24.gif';
import CODIGO25 from './images/gif/CODIGO25.gif';
import CODIGO26 from './images/gif/CODIGO26.gif';
import CODIGO27 from './images/gif/CODIGO27.gif';
import CODIGO28 from './images/gif/CODIGO28.gif';
import CODIGO29 from './images/gif/CODIGO29.gif';
import CODIGO30 from './images/gif/CODIGO30.gif';
import CODIGO31 from './images/gif/CODIGO31.gif';
import CODIGO32 from './images/gif/CODIGO32.gif';
import CODIGO33 from './images/gif/CODIGO33.gif';
import CODIGO34 from './images/gif/CODIGO34.gif';
import CODIGO35 from './images/gif/CODIGO35.gif';
import CODIGO36 from './images/gif/CODIGO36.gif';
import CODIGO37 from './images/gif/CODIGO37.gif';
import CODIGO38 from './images/gif/CODIGO38.gif';
import CODIGO39 from './images/gif/CODIGO39.gif';
import CODIGO40 from './images/gif/CODIGO40.gif';
import CODIGO41 from './images/gif/CODIGO41.gif';
import CODIGO42 from './images/gif/CODIGO42.gif';
import CODIGO43 from './images/gif/CODIGO43.gif';
import CODIGO44 from './images/gif/CODIGO44.gif';
import CODIGO45 from './images/gif/CODIGO45.gif';
import CODIGO46 from './images/gif/CODIGO46.gif';
import CODIGO47 from './images/gif/CODIGO47.gif';
import CODIGO48 from './images/gif/CODIGO48.gif';
import CODIGO49 from './images/gif/CODIGO49.gif';
import CODIGO50 from './images/gif/CODIGO50.gif';
import CODIGO51 from './images/gif/CODIGO51.gif';
import CODIGO52 from './images/gif/CODIGO52.gif';
import CODIGO53 from './images/gif/CODIGO53.gif';
import CODIGO54 from './images/gif/CODIGO54.gif';
import CODIGO56 from './images/gif/CODIGO56.gif';
import CODIGO58 from './images/gif/CODIGO58.gif';
import CODIGO59 from './images/gif/CODIGO59.gif';
import CODIGO60 from './images/gif/CODIGO60.gif';
import CODIGO61 from './images/gif/CODIGO61.gif';
import CODIGO62 from './images/gif/CODIGO62.gif';
import CODIGO63 from './images/gif/CODIGO63.gif';
import CODIGO64 from './images/gif/CODIGO64.gif';
import CODIGO65 from './images/gif/CODIGO65.gif';
import AURAMERC from './images/gif/AuraMerc.png';
import EmoteCamper from './images/gif/EmoteCamper.gif';
import EmoteEz from './images/gif/EmoteEz.gif';
import EmoteDiablo from './images/gif/EmoteDiablo.gif';
import AuraAbsorb from './images/gif/Aura Absorb.gif';
import AuraAzul from './images/gif/Aura Azul.gif';
import AuraRed from './images/gif/Aura Red.gif';
import AuraVerde from './images/gif/Aura Verde.gif';
import AuraWhite from './images/gif/Aura White.gif';
import Bronce from './images/gif/Bronce.gif';
import Charm from './images/gif/Charm.gif';
import DragonIce from './images/gif/Dragon Ice.gif';
import Gold from './images/gif/Gold.gif';
import GoldenWings from './images/gif/Golden Wings.gif';
import GreenGlow from './images/gif/Green Glow.gif';
import GreenSmoke from './images/gif/Green Smoke.gif';
import Gris from './images/gif/Gris.gif';
import Imposive from './images/gif/Imposive.gif';
import Japanese from './images/gif/Japanese.gif';
import LightningFlow from './images/gif/Lightning Flow.gif';
import LightningGlow from './images/gif/Lightning Glow.gif';
import LightningStorm from './images/gif/Lightning Storm.gif';
import OrbitalRed from './images/gif/Orbital Red.gif';
import Orbital from './images/gif/Orbital.gif';
import Oring from './images/gif/Oring.gif';
import Pentagram from './images/gif/Pentagram.gif';
import RedGlow from './images/gif/Red Glow.gif';
import SaiyajinBlanco from './images/gif/Saiyajin Blanco.gif';
import Saiyajin from './images/gif/Saiyajin.gif';
import Spirits from './images/gif/Spirits.gif';
import ToxicWhite from './images/gif/Toxic White.gif';
import TyraelGoldWings from './images/gif/Tyrael Gold Wings.gif';
import TyraelRedWings from './images/gif/Tyrael Red Wings.gif';
import TyraelWhiteWings from './images/gif/Tyrael White Wings.gif';
import Verde from './images/gif/Verde.gif';
import VioletGlow from './images/gif/Violet Glow.gif';
import Innersight from './images/gif/Innersight.gif';
import Markwolf from './images/gif/Markwolf.gif';
import Holyshock from './images/gif/Holyshock.gif';
import VioletStar from './images/gif/Violet Star.gif'

const Auras = () => {
  return (
    <div className="body-aura">
      <header className="header-aura">
        <h1 className="h1-aura">Lista de Auras</h1>
        <nav className="nav-aura">
          <ul className="ul-aura">
            <li className="li-aura">
            </li>
          </ul>
        </nav>
      </header>
      <main className="main-aura">
        <ul className="aura-list">
        <li><img src={TyraelGoldWings} alt="TyraelGoldWings" /> Tyrael Gold Wings $2500</li>
<li><img src={TyraelRedWings} alt="TyraelRedWings" /> Tyrael Red Wings $2500</li>
<li><img src={TyraelWhiteWings} alt="TyraelWhiteWings" /> Tyrael White Wings $2500</li>
<li><img src={GoldenWings} alt="GoldenWings" /> Golden Wings (No estan 100% Adaptadas) $2500</li>
<li><img src={DragonIce} alt="DragonIce" /> Dragon Ice</li>
<li><img src={SaiyajinBlanco} alt="SaiyajinBlanco" /> Saiyajin Blanco</li>
<li><img src={Saiyajin} alt="Saiyajin" /> Saiyajin</li>
<li><img src={Imposive} alt="Imposive" /> Imposive</li>
<li><img src={Spirits} alt="Spirits" /> Spirits</li>
<li><img src={AuraAbsorb} alt="AuraAbsorb" /> Aura Absorb</li>
<li><img src={AuraAzul} alt="AuraAzul" /> Aura Azul</li>
<li><img src={AuraRed} alt="AuraRed" /> Aura Red</li>
<li><img src={AuraVerde} alt="AuraVerde" /> Aura Verde</li>
<li><img src={AuraWhite} alt="AuraWhite" /> Aura White</li>
<li><img src={Verde} alt="Verde" /> Verde</li>
<li><img src={Bronce} alt="Bronce" /> Bronce</li>
<li><img src={Gold} alt="Gold" /> Gold</li>
<li><img src={Gris} alt="Gris" /> Gris</li>
<li><img src={Charm} alt="Charm" /> Charm</li>
<li><img src={GreenGlow} alt="GreenGlow" /> Green Glow</li>
<li><img src={GreenSmoke} alt="GreenSmoke" /> Green Smoke</li>
<li><img src={Japanese} alt="Japanese" /> Japanese</li>
<li><img src={LightningFlow} alt="LightningFlow" /> Lightning Flow</li>
<li><img src={LightningGlow} alt="LightningGlow" /> Lightning Glow</li>
<li><img src={LightningStorm} alt="LightningStorm" /> Lightning Storm</li>
<li><img src={Holyshock} alt="Holyshock" /> Holyshock</li>
<li><img src={OrbitalRed} alt="OrbitalRed" /> Orbital Red</li>
<li><img src={Pentagram} alt="Pentagram" /> Pentagram</li>
<li><img src={RedGlow} alt="RedGlow" /> Red Glow</li>

<li><img src={Orbital} alt="Orbital" /> Orbital</li>
<li><img src={Oring} alt="Oring" /> Oring</li>
<li><img src={ToxicWhite} alt="ToxicWhite" /> Toxic White</li>
<li><img src={VioletGlow} alt="VioletGlow" /> Violet Glow</li>
<li><img src={VioletStar} alt="VioletStar" /> Violet Star</li>
<li><img src={Innersight} alt="Innersight" /> Innersight</li>
<li><img src={Markwolf} alt="Markwolf" /> Markwolf</li>
{/* <li><img src={EmoteCamper} alt="EmoteCamper" /> Emote Camper</li>
<li><img src={EmoteEz} alt="EmoteEz" /> Emote Ez</li>
<li><img src={EmoteDiablo} alt="EmoteDiablo" /> Emote Diablo Pensando</li> */}
        <li><img src={AURAMERC} alt="AURAMERC" /> Merce: Puede usar cualquier aura a elección </li>
          <li><img src={CODIGO2} alt="RESIST FIRE" /> RESIST FIRE</li>
          <li><img src={CODIGO3} alt="RESIST COLD" /> RESIST COLD</li>
          <li><img src={CODIGO4} alt="RESIST LIGHT" /> RESIST LIGHT</li>
          <li><img src={CODIGO5} alt="RESIST ALL" /> RESIST ALL</li>
          <li><img src={CODIGO6} alt="COLD" /> AMPLIFY</li>
          <li><img src={CODIGO7} alt="FROZEN ARMOR" /> FROZEN ARMOR</li>
          <li><img src={CODIGO8} alt="INNER SIGHT" /> COLD</li>
          <li><img src={CODIGO9} alt="WEAKEN" /> INNER SIGHT</li>
          <li><img src={CODIGO10} alt="WEAKEN" /> WEAKEN</li>
          <li><img src={CODIGO11} alt="STUNNED" /> STUNNED</li>
          <li><img src={CODIGO12_1} alt="DIMVISION" /> DIMVISION</li>
          <li><img src={CODIGO13} alt="FETISH AURA" /> FETISH AURA</li>
          <li><img src={CODIGO14} alt="SHOUT" /> SHOUT</li>
          <li><img src={CODIGO15} alt="TAUNT" /> TAUNT</li>
          <li><img src={CODIGO16} alt="CONVICTION" /> CONVICTION</li>
          <li><img src={CODIGO17} alt="ENERGY SHIELD" /> ENERGY SHIELD</li>
          <li><img src={CODIGO18} alt="BATTLE ORDER" /> BATTLE ORDER</li>
          <li><img src={CODIGO19} alt="MIGHT" /> MIGHT</li>
          <li><img src={CODIGO20} alt="PRAYER" /> PRAYER</li>
          <li><img src={CODIGO21} alt="HOLY FIRE" /> HOLY FIRE</li>
          <li><img src={CODIGO22} alt="THORNS" /> THORNS</li>
          <li><img src={CODIGO23} alt="DEFIANCE" /> DEFIANCE</li>
          <li><img src={CODIGO24} alt="THUNDER STORM" /> THUNDER STORM</li>
          <li><img src={CODIGO25} alt="BLESSE DAIM" /> BLESSE DAIM</li>
          <li><img src={CODIGO26} alt="STAMINA" /> STAMINA</li>
          <li><img src={CODIGO27} alt="CONCETRACION" /> CONCETRACION</li>
          <li><img src={CODIGO28} alt="HOLYWIND" /> HOLYWIND</li>
          <li><img src={CODIGO29} alt="CLEANSING" /> CLEANSING</li>
          <li><img src={CODIGO30} alt="HOLYSHOCK" /> HOLYSHOCK</li>
          <li><img src={CODIGO31} alt="SANCTUARY" /> SANCTUARY</li>
          <li><img src={CODIGO32} alt="MEDITATION" /> MEDITATION</li>
          <li><img src={CODIGO33} alt="FANATISMO" /> FANATISMO</li>
          <li><img src={CODIGO34} alt="REDEMPTION" /> REDEMPTION</li>
          <li><img src={CODIGO35} alt="BATTLE COMAND" /> BATTLE COMAND</li>
          <li><img src={CODIGO36} alt="IRON MAIDEN" /> IRON MAIDEN</li>
          <li><img src={CODIGO37} alt="TERROR" /> TERROR</li>
          <li><img src={CODIGO38} alt="ATTRACT" /> ATTRACT</li>
          <li><img src={CODIGO39} alt="LIFE TAP" /> LIFE TAP</li>
          <li><img src={CODIGO40} alt="CONFUSE" /> CONFUSE</li>
          <li><img src={CODIGO41} alt="DECREPIFY" /> DECREPIFY</li>
          <li><img src={CODIGO42} alt="LOWER RESIST" /> LOWER RESIST</li>
          <li><img src={CODIGO43} alt="BARBS" /> BARBS</li>
          <li><img src={CODIGO44} alt="FINGERMAGE CURSE" /> FINGERMAGE CURSE</li>
          <li><img src={CODIGO45} alt="BATTLE CRY" /> BATTLE CRY</li>
          <li><img src={CODIGO46} alt="RED" /> RED</li>
          <li><img src={CODIGO47} alt="FULL SET" /> FULL SET</li>
          <li><img src={CODIGO48} alt="FRENZY" /> FRENZY</li>
          <li><img src={CODIGO49} alt="BERSEKER" /> BERSEKER</li>
          <li><img src={CODIGO50} alt="REVIVE" /> REVIVE</li>
          <li><img src={CODIGO51} alt="MONFRENZY" /> MONFRENZY</li>
          <li><img src={CODIGO52} alt="PREGNANT" /> PREGNANT</li>
          <li><img src={CODIGO53} alt="RABIES" /> RABIES</li>
          <li><img src={CODIGO54} alt="DEFENSE CURSE" /> DEFENSE CURSE</li>
          <li><img src={CODIGO56} alt="BURNING" /> BURNING</li>
          <li><img src={CODIGO58} alt="FENRIS RANGE" /> FENRIS RANGE</li>
          <li><img src={CODIGO59} alt="WOLVERINE" /> WOLVERINE</li>
          <li><img src={CODIGO60} alt="WHIRLWIND" /> WHIRLWIND</li>
          <li><img src={CODIGO61} alt="QUICKNESS" /> QUICKNESS</li>
          <li><img src={CODIGO62} alt="BLOODLUST" /> BLOODLUST</li>
          <li><img src={CODIGO63} alt="CLOAKED" /> CLOAKED</li>
          <li><img src={CODIGO64} alt="OAKSAGE" /> OAKSAGE</li>
          <li><img src={CODIGO65} alt="BLADESHIELD" /> BLADESHIELD</li>
        </ul>
        <p className='aura-aclaracion'>Puedes usar 3 auras a la vez por cada SC</p>
        <p className='aura-aclaracion'> e Incluso aplicarlas en Mercenarios.</p>
        <br></br>
        <div className="button-container-dona">
  <a href="https://api.whatsapp.com/send/?phone=5491154003420" className="contacto-dona" rel="noopener noreferrer">Contacto</a>
</div>
<br></br>
        <p className='credits'>Developed by Guido Favara - Credits of those Gif's to Genco</p>
      </main>

    </div>
  );
};

export default Auras;
